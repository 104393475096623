import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './App.css';
import StepContainer from './components/StepContainer';
import StepIndex from './components/StepIndex';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentStep: 1,
      formData: {}
    };

    this.updateData = this.updateData.bind(this);

    const { location } = window;
    const query = new URLSearchParams(location.search);
    
    const token = query.get('token');

    const lang = query.get('lang');

    if(lang)
      window["data"]["lang"] = lang;

  }


  setTouchStart(a){
    this.setState({touchStart:a})
  }

  setTouchEnd(a){
    this.setState({touchEnd:a})
  }

  handleTouchStart(e) {
    this.setTouchStart(e.targetTouches[0].clientX);
  }

  handleTouchMove(e) {
    this.setTouchEnd(e.targetTouches[0].clientX);
  }

  handleTouchEnd() {
    
    if(!this.state.touchEnd)
      return;

    if (this.state.touchStart - this.state.touchEnd > 150) {
      this.updateStep(true);
    }

    if (this.state.touchStart - this.state.touchEnd < -150) {
      this.updateStep(false);
    }

    this.setState({touchStart:null})
    this.setState({touchEnd:null})

  }


  render() {

    const { t } = this.props;

    return (
      <div 
      onTouchStart={(e) => {this.handleTouchStart(e)}}
      onTouchMove={(e) => {this.handleTouchMove(e)}}
      onTouchEnd={(e) => {this.handleTouchEnd(e)}}

      id="MobileWizard">
        <StepContainer updateData={this.updateData} currentStep={this.state.currentStep} />
        <div className="step_footer" style={{ display: this.state.currentStep === 5 ? 'none' : 'block' }}>
          <StepIndex currentStep={this.state.currentStep} />
        <div onClick={() => { this.nextStep(); }} className="btn_next">{t('btn_go_on')}</div>
        </div>
        <div style={{ display : this.state.loading ? "block" : "none"}} className="loader"><div><div><div className="shapes-5"></div></div></div></div>
      </div>
    );
  }

  updateData(attr, data) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [attr]: data
      }
    }));
  }

  nextStep() {
    this.setState({
      currentStep: this.state.currentStep + 1,
      formData: this.state.formData
    });

    if (this.state.currentStep === 4) {
      this.sendData()
      //setTimeout(() => { this.setState({loading: false}); },3000);
    }
  }

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
  
      formData.append(parentKey, value);
    }
  }

  sendData(){
    this.setState({loading: true});

    let data = {'f':"templateWizard", "lang":window["data"]["lang"], "defaultKey": window.data.k, "data_import" : {...this.state.formData}};

    const formData  = new FormData();

    this.buildFormData(formData,data);

    const requestOptions = {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: formData//JSON.stringify(data)
    };

    let domain = window["data"]["d"];
    const url = "https://"+domain+"/manager/includer.php"

    fetch(url,requestOptions)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({loading: false});
      },
      (error) => {
        console.log("Error : ", error);
      }
    )
    .catch(error => {
      console.log("Catched error : ", error);
    })
  }



  updateStep(next){


    if(this.state.currentStep === 5)
      return;

    if(next && this.state.currentStep<4){
      this.setState({
        currentStep: this.state.currentStep + 1,
        formData: this.state.formData
      });
  
    }
    else if(!next && this.state.currentStep>1){
      this.setState({
        currentStep: this.state.currentStep - 1,
        formData: this.state.formData
      });
  
    }



  }

}

export default withTranslation()(App);

import { Component } from 'react';

class StepIndex extends Component {


    render() {

        return (
            <div className="step_index" data-currstep={this.props.currentStep}>
                {
                    [1, 2, 3, 4, 5].map((i) => (
                        <div className="dot" key={i} data-step={i}></div>
                    ))
                }
            </div>
        );
    }


}

export default StepIndex;
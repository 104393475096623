import { Component } from 'react';
import { withTranslation } from 'react-i18next';


class Step1 extends Component{

    constructor(props){
        super(props);
        this.props = props;

        this.state = {current_cat : 1};

        const { t } = this.props;
        
        this.categories = [
            { id: 1, id_category: 2, title: t('category_personal'), pages: ["about", "news", "blog-detail", "portfolio", "gallery", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-blog", "p-portfolio", "p-gallery", "p-contacts",  "p-newsletter"] },
            { id: 2, id_category: 130, title: t('category_company'), pages: ["about", "news", "blog-detail", "services", "team", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-blog", "p-offline-services", "p-team", "p-contacts",  "p-newsletter"] },
            { id: 3, id_category: 2, title: t('category_professional'), pages: ["about", "book-a-service", "portfolio", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-online-services", "p-portfolio", "p-contacts",  "p-newsletter"] },
            { id: 4, id_category: 137, title: t('category_blog'), pages: ["about", "news", "blog-detail", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-blog", "p-portfolio", "p-contacts",  "p-newsletter"] },
            { id: 5, id_category: 55, title: t('category_ecommerce'), pages: ["about", "news", "blog-detail", "shop", "ecommerce-detail", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-blog", "p-shop", "p-contacts",  "p-newsletter"] },
            { id: 6, id_category: 125, title: t('category_services'), pages: ["about", "book-a-service", "services", "gallery", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-online-services", "p-offline-services", "p-gallery", "p-contacts",  "p-newsletter"] },
            { id: 7, id_category: 44, title: t('category_booking'), pages: ["about", "rooms", "services", "gallery", "team", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-booking", "p-offline-services", "p-gallery", "p-team", "p-contacts",  "p-newsletter"] },
            { id: 8, id_category: 68, title: t('category_restaurant'), pages: ["about", "menu", "events", "events-detail", "gallery", "team", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-restaurant-menu", "p-events", "p-gallery", "p-team", "p-contacts",  "p-newsletter"] },
            { id: 9, id_category: 8, title: t('category_design'), pages: ["about", "news", "blog-detail", "portfolio", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-blog", "p-portfolio", "p-contacts",  "p-newsletter"] },
            { id: 10, id_category: 66, title: t('category_advertising'), pages: ["about", "book-a-service", "services", "team", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-online-services", "p-offline-services", "p-team", "p-contacts",  "p-newsletter"] },
            { id: 11, id_category: 1, title: t('category_estate'),  pages: ["about", "services", "team", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-offline-services", "p-team", "p-contacts",  "p-newsletter"] },
            { id: 12, id_category: 37, title: t('category_health'), pages: ["about", "news", "blog-detail", "services", "team", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-blog", "p-offline-services", "p-team", "p-contacts",  "p-newsletter"] },
            { id: 13, id_category: 78, title: t('category_portfolio'), pages: ["about", "news", "blog-detail", "portfolio", "contact", "cookie-policy", "privacy-policy"], presets: ["p-home", "p-about", "p-about-2", "p-blog", "p-portfolio"] }
        ];
    }

    componentDidMount(){
        this.changeSelection(this.categories[0]);
    }

    changeSelection(cat){
        this.setState({current_cat : cat.id});
        this.props.updateData("id_category",cat.id_category);
        this.props.updateData("pages",cat.pages);
        this.props.updateData("presets",cat.presets); 
    }

    render(){

        const { t } = this.props;


        return (
            <div className="step" data-step="1">
                    <div className="step_title">
                        <p>{t('wizard_step_title_1')}</p>
                        <small>{t('wizard_step_subtitle_1')}</small>
                    </div>
                    <div className="step_content">
                        <ul className="categories">
                            {
                                this.categories.map((category) => (
                                    <li onClick={() => this.changeSelection(category)} className={category.id===this.state.current_cat?'selected':''} key={category.id} data-id={category.id_category}>{category.title}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
        );
    }

}

export default withTranslation()(Step1);

import { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Step4 extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            currentSelected: 0
        };

        this.colors = [["780000","C1121F"],["003049","669BBC"],["00263E","54A663"],["898f8e","FFAF87"],["4C4921","EE8F38"],["2E294E","F46036"],["481D24","FFC857"],["FB3640","B3AF8F"],["03080A","6C6F74"],["3B3561","DD7373"],["8D6F64","C5D86D"],["FEB0AD","F0DF6B"],["AED6EA","F0DF6B"],["2E2E2E","BF8D7C"],["235552","F1B049"],["24272B","01A7C2"],["415A77","94A89A"],["54428E","797596"]];

    }

    componentDidMount(){
        this.changeSelection(this.colors[0],0);
    }

    changeSelection(color, color_index) {
        this.setState({
            currentSelected: color_index
        });

        this.props.updateData("colors", color);
    }

    render() {

        const { t } = this.props;

        return (
            <div className="step" data-step="4">
                <div className="step_title">
                    <p>{t('wizard_step_color')}</p>
                    <small>{t('wizard_select_style_palette_label')}</small>
                </div>
                <div className="step_content">
                    <ul className="palette">
                        {
                            this.colors.map((color, index) => (
                                <li onClick={() => {this.changeSelection(color,index)}} className={index===this.state.currentSelected?'selected':''} key={index}>
                                    {
                                        color.map((single_color) => (
                                            <div data-color={single_color} style={{backgroundColor: '#'+single_color}}></div>
                                        ))
                                    }
                                </li>
                            ))
                        }
                        
                    </ul>
                </div>
            </div>
        );
    }

}


export default withTranslation()(Step4);
import {Component} from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

class StepContainer extends Component{

    render(){

        return (
            <div className="steps_container" data-currstep={this.props.currentStep}>
                <Step1 updateData={this.props.updateData}/>
                <Step2 updateData={this.props.updateData}/>
                <Step3 updateData={this.props.updateData}/>
                <Step4 updateData={this.props.updateData}/>
                <Step5 url={"https://"+window["data"]["d"]} />
            </div>
        );
    }
    

}


export default StepContainer;
import { Component } from 'react';
import end_image from './../img/test.jpg';
import { withTranslation } from 'react-i18next';

class Step5 extends Component {

    constructor(props) {
        super(props);
        this.props = props;

    }

    render() {

        const { t } = this.props;

        return (
            <div className="step" data-step="5">
                <div className="step_title">
                    <img alt="test" src={end_image} />
                    <p>{t('wizard_finalstep_title')}</p>
                    <small>{t('wizard_finalstep_description')}</small>
                </div>
                <div className="step_content">
                    <a href={this.props.url} className="website_preview">{t('wizard_finalstep_button')}</a>
                </div>
            </div>
        );
    }

}


export default withTranslation()(Step5);
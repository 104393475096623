import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import back1 from './../img/1.jpeg';
import back2 from './../img/2.jpeg';
import back3 from './../img/3.jpeg';
import back4 from './../img/4.jpeg';

class Step2 extends Component{

    constructor(props){
        super(props);
        this.props = props;

        this.state = {
            currentSelected : 1
        }

        this.offsets = {};
        this.offsets[1] = {"start" : 0,"start_mobile" : 0, "middle" : 72, "middle_mobile" : 46};
        this.offsets[2] = {"start" : 0,"start_mobile" : 150, "middle" : 72, "middle_mobile" : 46};
        this.offsets[3] = {"start" : 0,"start_mobile" : 0, "middle" : 72, "middle_mobile" : 46};
        this.offsets[4] = {"start" : 150,"start_mobile" : 150, "middle" : 72, "middle_mobile" : 46};
    }

    componentDidMount(){
        this.changeSelection(1);
    }

    changeSelection(index){
        this.setState({
            currentSelected : index
        });

        this.props.updateData("modello","template-0"+index);
        this.props.updateData("offsets",this.offsets[index]);
        
    }

    render(){

        const { t } = this.props;
        
        return (
            <div className="step" data-step="2">
                    <div className="step_title">
                        <p>{t('wizard_step_title_2')}</p>
                        <small>{t('wizard_step_subtitle_2')}</small>
                    </div>
                    <div className="step_content">
                        <ul className="themes">
                            <li onClick={() => {this.changeSelection(1)}} className={this.state.currentSelected===1?'selected':''} style={{backgroundImage: `url(${back1})`}}></li>
                            <li onClick={() => {this.changeSelection(2)}} className={this.state.currentSelected===2?'selected':''}  style={{backgroundImage: `url(${back2})`}}></li>
                            <li onClick={() => {this.changeSelection(3)}} className={this.state.currentSelected===3?'selected':''}  style={{backgroundImage: `url(${back3})`}}></li>
                            <li onClick={() => {this.changeSelection(4)}} className={this.state.currentSelected===4?'selected':''}  style={{backgroundImage: `url(${back4})`}}></li>
                        </ul>
                    </div>
                </div>
        );
    }

}


export default withTranslation()(Step2);
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Step3 extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            currentSelected: "Heebo"
        };

        this.fonts = [
            "Heebo",
            "Playfair Display",
            "Parisienne",
            "Bebas Nue", 
            "Arimo", 
            "Montserrat", "Noto Serif Display", 
            "Bitter", "Noto sans", 
            "Abril Fatface", "Pacifico", "Raleway"
        ];

    }

    componentDidMount(){
        this.changeSelection(this.fonts[0]);
    }

    changeSelection(font) {
        this.setState({
            currentSelected: font
        });

        this.props.updateData("font", font);
    }

    render() {

        const { t } = this.props;

        return (
            <div className="step" data-step="3">
                <div className="step_title">
                    <p>{t('wizard_step_font')}</p>
                    <small>{t('wizard_select_style_font_label')}</small>
                </div>
                <div className="step_content">
                    <ul className="font">
                        {
                            this.fonts.map((font,index) => (
                                <li onClick={() => {this.changeSelection(font)}} className={font===this.state.currentSelected?'selected':''} key={index} data-font={font} style={{fontFamily: font}}>{font}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        );
    }

}


export default withTranslation()(Step3);
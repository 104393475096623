import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const { location } = window;
const query = new URLSearchParams(location.search);
const lang = query.get('lang');
if(lang){
  window["data"]["lang"] = lang;
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    backend: {
        loadPath: `./locales/{{lng}}.json`
    },
    lng: window["data"]["lang"],
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });


export default i18n;